import React, { useEffect, useReducer, useState } from "react";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import { i18n } from "../../translate/i18n";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import { Avatar, Box, Button, Divider, IconButton, InputAdornment, Paper, Rating, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip, Typography } from "@mui/material";
import { Comment, Download, Search } from "@mui/icons-material";
import makeStyles from '@mui/styles/makeStyles';
import ReviewsChart from "./ReviewsChart";
import useReviews from "../../hooks/useReviews";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import { endOfDay, format, startOfMonth } from "date-fns";
import ReviewsFilter from "./ReviewsFilter";
import api from "../../services/api";
import openSocket from "../../services/socket-io";
import toastError from "../../errors/toastError";
import { toast } from "react-toastify";
import ButtonWithSpinner from "../../components/ButtonWithSpinner";

const useStyles = makeStyles((theme) => ({
    mainPaper: {
        flex: 1,
        display: "flex",
        margin: theme.spacing(1),
        marginTop: 0,
        padding: theme.spacing(1),
        overflowY: "hidden",
        ...theme.scrollbarStyles,
    },
    tableCustomRow: {
        '& td, & th': {
            borderColor: theme.palette.divider,
        },
    },
    tableHiddenBorder: {
        '& td, & th': {
            border: 0,
        },
    },
    iconButton: {
        color: theme.palette.text.secondary
    },
    customTableCell: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: 10
    },
    formControl: {
        minWidth: 120,
    },
}));

const reducer = (state, action) => {
    if (action.type === "LOAD_REVIEWS") {
        const reviews = action.payload;
        const newReviews = [];

        reviews.forEach((review) => {
            const reviewIndex = state.findIndex((q) => q.id === review.id);
            if (reviewIndex !== -1) {
                state[reviewIndex] = review;
            } else {
                newReviews.push(review);
            }
        });

        return [...state, ...newReviews];
    }

    if (action.type === "UPDATE_REVIEW") {
        const review = action.payload;
        const reviewIndex = state.findIndex((u) => u.id === review.id);

        if (reviewIndex !== -1) {
            state[reviewIndex] = review;
            return [...state];
        } else {
            return [review, ...state];
        }
    }

    if (action.type === "DELETE_REVIEW") {
        const reviewId = action.payload;
        const reviewIndex = state.findIndex((q) => q.id === reviewId);
        if (reviewIndex !== -1) {
            state.splice(reviewIndex, 1);
        }
        return [...state];
    }

    if (action.type === "RESET") {
        return [];
    }
};

const Reviews = () => {
    const classes = useStyles();
    const [searchParam, setSearchParam] = useState('');
    const [pageNumber, setPageNumber] = useState(1);
    const today = new Date();
    const firstDayOfMonth = startOfMonth(today);
    const lastHourOfToday = endOfDay(today);
    const [filter, setFilter] = useState({ start: firstDayOfMonth, end: lastHourOfToday, rating: '' });
    const [reviews, dispatch] = useReducer(reducer, []);
    const [promotorsAmnt, setPromotorsAmnt] = useState(0);
    const [totalAmnt, setTotalAmnt] = useState(0);
    const [loading, setLoading] = useState(false);
    const [loadingCSVdata, setLoadingCSVdata] = useState(false);
    const [hasMore, setHasMore] = useState(false);

    useEffect(() => {
        dispatch({ type: "RESET" });
        setPageNumber(1);
    }, [searchParam, filter]);

    useEffect(() => {
        setLoading(true);
        const fetchReviews = async () => {
            try {
                const { data } = await api.get("/reviews", {
                    params: {
                        searchParam,
                        pageNumber,
                        searchRating: filter.rating,
                        userId: filter.user,
                        queueId: filter.queue,
                        dateStart: filter.start,
                        dateEnd: filter.end
                    }
                });
                dispatch({ type: "LOAD_REVIEWS", payload: data.reviews });
                setHasMore(data.hasMore);
                setPromotorsAmnt(data.promotorsAmnt);
                setTotalAmnt(data.totalAmnt);
            } catch (err) {
                toastError(err);
            }
            setLoading(false);
        };
        fetchReviews();
    }, [searchParam, pageNumber, filter]);


    useEffect(() => {
        const socket = openSocket();

        socket.on("review", (data) => {
            if (data.action === "update" || data.action === "create") {
                dispatch({ type: "UPDATE_REVIEW", payload: data.review });

            }

            if (data.action === "delete") {
                dispatch({ type: "DELETE_REVIEW", payload: data.reviewId });
            }
        });

        return () => {
            socket.disconnect();
        };
    }, []);

    const exportToCSV = async () => {
        setLoadingCSVdata(true);
        try {
            const { data } = await api.get("/reviews", {
                params: {
                    searchParam,
                    searchRating: filter.rating,
                    userId: filter.user,
                    queueId: filter.queue,
                    dateStart: filter.start,
                    dateEnd: filter.end,
                    showAll: true
                }
            });
            // Utilizar aspas duplas dentro da string porque pode identificar como outra coluna quando tem ","(virgulas)
            const rows = [
                [
                    `"${i18n.t("reviews.table.reviewId")}"`,
                    `"${i18n.t("reviews.table.ticket")}"`,
                    `"${i18n.t("reviews.table.user")}"`,
                    `"${i18n.t("reviews.table.contact")}"`,
                    `"${i18n.t("reviews.table.email")}"`,
                    `"${i18n.t("reviews.table.company")}"`,
                    `"${i18n.t("reviews.table.queue")}"`,
                    `"${i18n.t("reviews.table.comment")}"`,
                    `"${i18n.t("reviews.table.rating")}"`,
                    `"${i18n.t("reviews.table.ratedIn")}"`
                ],
                ...data?.reviews.map((review) => [
                    `"${review.id}"`,
                    `"${review.ticketId}"`,
                    `"${review.ticket.user?.name}"`,
                    `"${review.ticket.contact?.name}"`,
                    `"${review.ticket.contact?.email}"`,
                    `"${review.ticket.contact?.company}"`,
                    `"${review.ticket?.queue?.name}"`,
                    `"${review?.comment.trim()}"`,
                    `"${review.rating}"`,
                    `"${new Date(review.createdAt).toLocaleString().replace(",", " ")}"`
                ])
            ];

            const csvContent = rows.map(e => e.join(",")).join("\n");
            const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
            const url = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = `reviews_${filter.start.toLocaleDateString() + "-" + filter.end.toLocaleDateString()}.csv`;
            a.click();
            URL.revokeObjectURL(url);
            toast.success(i18n.t("reviews.toasts.CSVsuccess"));
        } catch (err) {
            toastError(err);
        }
        setLoadingCSVdata(false);

    }

    const handleSearch = (event) => {
        setSearchParam(event.target.value);
    };

    const loadMore = () => {
        setPageNumber((prevState) => prevState + 1);
    };

    const handleScroll = (e) => {
        if (!hasMore || loading) return;
        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
        if (scrollHeight - (scrollTop + 100) < clientHeight) {
            loadMore();
        }
    };

    return (
        <MainContainer>
            <MainHeader>
                <Title>
                    {i18n.t("reviews.title")}
                </Title>
                <MainHeaderButtonsWrapper>
                    <TextField
                        placeholder={i18n.t("contacts.searchPlaceholder")}
                        type="search"
                        value={searchParam}
                        onChange={handleSearch}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search style={{ color: "gray" }} />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <ReviewsFilter classes={classes} values={filter} onChange={setFilter} />
                    <ButtonWithSpinner
                        endIcon={<Download />}
                        variant="outlined"
                        onClick={exportToCSV}
                        loading={loadingCSVdata}
                    >
                        {i18n.t("reviews.buttons.export")}
                    </ButtonWithSpinner>
                </MainHeaderButtonsWrapper>
            </MainHeader>
            <Paper
                className={classes.mainPaper}
                variant="outlined"
            >
                <Paper sx={{ flex: 1, overflowY: "scroll", }} onScroll={handleScroll}>
                    <Table size='small'>
                        <TableHead>
                            <TableRow className={classes.tableCustomRow}>
                                <TableCell padding="checkbox" />
                                <TableCell align="center">
                                    {i18n.t("reviews.table.contact")}
                                </TableCell>
                                <TableCell align="center">
                                    {i18n.t("reviews.table.user")}
                                </TableCell>
                                <TableCell align="center">
                                    {i18n.t("reviews.table.ticket")}
                                </TableCell>
                                <TableCell align="center">
                                    {i18n.t("reviews.table.comment")}
                                </TableCell>
                                <TableCell align="center">
                                    {i18n.t("reviews.table.rating")}
                                </TableCell>
                                <TableCell align="center">
                                    {i18n.t("reviews.table.ratedIn")}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {reviews && reviews.map((review) => (<>
                                <TableRow className={classes.tableCustomRow} key={review.id}>
                                    <TableCell align="center" padding="checkbox">
                                        <Avatar src={review.ticket.contact?.profilePicUrl} />
                                    </TableCell>
                                    <TableCell align="center">
                                        {review.ticket?.contact?.name}
                                    </TableCell>
                                    <TableCell align="center">
                                        {review.ticket?.user?.name}
                                    </TableCell>
                                    <TableCell align="center">
                                        {`#${review.ticketId}`}
                                    </TableCell>
                                    <TableCell align="center">
                                        <div className={classes.customTableCell}>

                                            <Tooltip arrow title={<Typography>{review.comment}</Typography>}>
                                                <Typography
                                                    style={{ maxWidth: 250, align: "center" }}
                                                    noWrap
                                                    variant="body2"
                                                >
                                                    {review.comment}
                                                </Typography>
                                            </Tooltip>
                                        </div>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Rating name="read-only" value={review.rating} readOnly />
                                    </TableCell>
                                    <TableCell align="center">
                                        {format(new Date(review.createdAt), "dd/MM/yyyy HH:mm")}
                                    </TableCell>
                                </TableRow>
                            </>))}
                            {loading && <TableRowSkeleton columns={6} avatar={1} />}

                        </TableBody>
                    </Table>
                </Paper>
                <Divider orientation="vertical" />
                <Box sx={{ width: 300, overflow: "hidden" }}>
                    <ReviewsChart promotorsAmnt={promotorsAmnt} totalAmnt={totalAmnt} />
                </Box>

            </Paper>
        </MainContainer>
    )
}
export default Reviews;